import { HttpClient } from '@angular/common/http';
import { Injectable, inject, Signal, signal, WritableSignal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { StateService } from '../../shared/service/state.service';

export interface MenuChangeEvent {
    key: string;
    routeEvent?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private _httpClient = inject(HttpClient);
    private translocoService = inject(TranslocoService);
    private _stateService = inject(StateService);
    public menuItems: WritableSignal<MenuItem[]> = signal<MenuItem[]>([]);
    private menuSource = new Subject<MenuChangeEvent>();
    private resetSource = new Subject();

    menuSource$ = this.menuSource.asObservable();
    resetSource$ = this.resetSource.asObservable();
    loading = signal<boolean>(false);

    getMenuModule(moduleId: string) {
        this.loading.set(true);
        this._httpClient.get<MenuItem[]>(`Menu/GetMenuItemsByModule?moduleId=${moduleId}&institutionalView=${this._stateService.institutionalView}`).pipe().subscribe({
            next: data => {
                this.menuItems.set(data);
                this.loading.set(false);
            },
            error: error => {
            }
        });
    }

    onMenuStateChange(event: MenuChangeEvent) {
        this.menuSource.next(event);
    }

    reset() {
        this.resetSource.next(true);
    }
}
